import React, { useEffect } from "react";
import { Col, Row } from "antd";
import Boxes from "./Boxes";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import axios from "axios";

import { Chart } from "react-google-charts";
import Boxes2 from "./Boxes2";
import UserrTable from "./UserrTable";
import CenteralHealthLine from "./CenteralHealthLineChart/CenteralHealthLine";
import Transaction from "../Transaction/Transaction";
function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allapiAction.dashboard({}));
    dispatch(allapiAction.dashboardChart({}));

    return () => { };
  }, []);

  const deshboard_state = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );
  const data2 = [
    ["Year", "Sales", "Expenses"],
    ["Monday", 20000, 30000],
    ["Tuesday", 35000, 15000],
    ["Wednesday", 38000, 14000],
    ["Thursday", 25000, 35000],
    ["Friday", 34000, 22000],
    ["Saturday", 42000, 34000],
    ["Sunday", 18000, 38000],
  ];
  const data = deshboard_state;

  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  // console.log("3" + "3" - "3");

  return (
    <div type="flex">

      <div className=" p-10">
        {/* <CenteralHealthLine /> */}
        {/* <Boxes /> */}
        <Boxes2 />

        <div className="dashboardBoxShad m-5 mt-10" style={{ padding: '20px' }}>
          <Row>
            <Col span={24}>
              <div>
                <div className="fw-600" style={{ color: '#DFDDC7', fontWeight: '900', marginBottom: '10px' }}>Top 10 Users</div>
              </div>
            </Col>
          </Row>

          <UserrTable />


        </div>


        <div className="dashboardBoxShad m-5 mt-10" style={{ padding: '20px' }}>
          <Row>
            <Col span={24}>
              <div>
                <div className="fw-600" style={{ color: '#DFDDC7', fontWeight: '900', marginBottom: '10px' }}>Transactions</div>
              </div>
            </Col>
          </Row>

          <Transaction />


        </div>


        <div className="dashboardBoxShad m-5 mt-10 p-10 mb-10">
          <div className="flex justify-content-between" style={{ marginBottom: '10px' }}>
            <div>
              <div className="fw-600" style={{ color: '#DFDDC7', fontWeight: '900', }}>Total Commission</div>
            </div>
            <div className="flex justify-content-end gap20 align-items-center">
              <div className="flex gap30">
                <div className="relative">
                  <span className="ct_chart_status beforeActive">
                    Active
                  </span>
                </div>
                <div className="relative">
                  <span className="ct_chart_status beforeInactive">
                    Inactive
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Chart
            chartType="LineChart"
            // width="100%"
            height="400px"

            data={data}
            options={{
              fontName: "Open Sans",
              fontSize: 14,
              color: "#212529",
              tooltip: { isHtml: true },
              legend: { position: "none" },
              colors: ["#fecc4e", "#1559a2"],

              curveType: "function",
              hAxis: {
                textStyle: {
                  whiteSpace: "wrap",
                  fontName: "Open Sans",
                  fontSize: 14,
                  color: "#ffffff",
                },
              },

              vAxis: {
                textStyle: {
                  whiteSpace: "wrap",
                  fontName: "Open Sans",
                  fontSize: 14,
                  color: "#ffffff",
                },
              },
              backgroundColor: {
                fill: '#1E201E',
                fillOpacity: 0.8
              },

              chartArea: {

                left: 70,
                top: 10,
                bottom: 40,
                width: "100%",
                height: "100%",
              },
            }}
          />
          {/* <CenteralHealthLine/> */}
        </div>


      </div>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
          <div>
            <Boxes />
          </div>
        </Col> */}

    </div>
  );
}

export default Home;
