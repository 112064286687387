import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Quiz from "../Component/Quiz/Quiz";
import Question from "../Component/Question/Question";
import TestTable from "../Component/TestTable/TestTable";
import Bettel from "../Component/MatchConflicts/Bettel";
import BettelReq from "../Component/MatchConflicts/BettelReq";
import WithdrawalRequest from "../Component/Withdrawal/WithdrawalRequest";
import Kyc from "../Component/Kyc/Kyc";
import Transaction from "../Component/Transaction/Transaction";
import UserDetails from "../Component/User/UserDetails";
import RecentUser from "../Component/User/RecentUser"
import Bettalview from "../Component/Bettel/Bettalview";
import Setting from "../Component/Setting/Setting";
import BettalviewWin from "../Component/Bettel/BettalviewWin";
import AccountList from "../Component/Account/Account";
import PointRequest from "../Component/Account/PointRequest";
import BettleAuto from "../Component/Bettel/BettleAuto";
import BettlePopular from "../Component/Bettel/BattlePopular";
import BettleSnake from "../Component/Bettel/Battlesnake";
import Message from "../Component/Message/Message";
import RecentPayment from '../Component/Payment/RecentPayment'
import CompleteBattel from '../Component/MatchConflicts/CompleteBattel'
import RecenteApprove from '../Component/Withdrawal/RecenteApprove'
import TopPlayer from '../Component/Top10/TopPlayer'
import OpenBattel from '../Component/MatchConflicts/OpenBattel'
function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
   
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
         
          <Route path="/user-details/:id" element={<UserDetails />} />
          <Route path="/battle-view/:id" element={<Bettalview />} />
          <Route path="/battle-view-win/:id" element={<BettalviewWin />} />
          <Route path="/users/contact/:id" element={<Contact />} />

          <Route path="/battle-classic-auto" element={<BettleAuto />} />
          <Route path="/battle-popular" element={<BettlePopular />} />
          <Route path="/battle-snake-and-ladder" element={<BettleSnake />} />

          {/* manual account list */}
          <Route path="/account-list" element={<AccountList />} />

          {/* point */}
          <Route path="/point-request" element={<PointRequest />} />

          {/* setting */}
          <Route path="/setting" element={<Setting />} />

          {/* transaction */}
          <Route path="/transaction" element={<Transaction />} />

          {/* message */}
          <Route path="/message" element={<Message />} />

          {/* payment */}
          <Route path="/payment-recent" element={<RecentPayment />} />

          {/* match conflicts */}
          <Route path="/complete-battel" element={<CompleteBattel />} />
          <Route path="/winner-request" element={<BettelReq />} />
          <Route path="/battle" element={<Bettel />} />
          <Route path="/open-battel" element={<OpenBattel />} />



          {/* KYC REQUEST */}
          <Route path="/kyc-request" element={<Kyc />} />

          {/* withdrawal  */}
          <Route path="/withdrawal-request" element={<WithdrawalRequest />} />
          <Route path="/RecenteApprove" element={<RecenteApprove />} />

          {/* users */}
          <Route path="/users" element={<User />} />
          <Route path="/recent-user" element={<RecentUser />} />

          {/* TopPlayer */}
          <Route path="/top-10" element={<TopPlayer />} />

        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
