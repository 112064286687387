import { Col, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import HeadingBack from "../component/HeadingBack";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import moment from "moment";
import {
  UserOutlined,
  WalletOutlined,
  DeleteOutlined,
  TrophyOutlined,
  QrcodeOutlined,
  MobileOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { Pagination } from "antd";
import styled from "styled-components";
const CustomPagination = styled(Pagination)`
  .ant-pagination-item {
    background-color: #40189d;
    border: 1px solid #ffffff;
    color: #ffffff;
  }

  .ant-pagination-item-active {
    border-color: #1890ff;
    color: #1890ff;
  }

  .ant-pagination-item:hover {
    background-color: #e6f7ff;
    border-color: #91d5ff;
    color: #000000;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    color: #1890ff;
    background-color: #40189d;
  }

  .ant-pagination-prev:hover,
  .ant-pagination-next:hover {
    color: #40a9ff;
    background-color: #40189d;
  }
`;

function UserDetails() {
  const dispatch = useDispatch();
  const data = useParams();
  const navigate = useNavigate();

  const details = useSelector((state) =>
    state?.allapi?.user_details ? state?.allapi?.user_details : {}
  );

  // console.log(details);

  const [statusselect, setstatusselect] = useState("");
  const [statusselectreas, setstatusselectreas] = useState("");

  useEffect(() => {
    setstatusselect(details?.keyverify);
    setstatusselectreas(details?.accountId?.reason);
  }, [details]);

  const [show, setShow] = useState(false);
  const [showcupdate, setShowUpdate] = useState(false);
  const [dataimg, setdataimg] = useState("");

  console.log(show);
  console.log(showcupdate);
  

  const handleClose = () => {
    setdataimg("");
    setShow(false);
  };
  const handleCloseUpdate = () => {
    // setdataimg('')
    setShowUpdate(false);
  };
  const hendleimgView = (e) => {
    setdataimg(e);
    setShow(true);
  };

  const hendletosubmitVerify = (e) => {
    // verifykeyByAdmin
    setShowUpdate(true);
    // dispatch(allapiAction.verifykeyByAdmin({_id: details?._id, keyverify: e}))

    return () => {};
  };

  const hendlesubmitup = () => {
    setShowUpdate(false);
    dispatch(
      allapiAction.verifykeyByAdmin({
        _id: details?._id,
        keyverify: statusselect,
        reason: statusselectreas,
      })
    );

    return () => {};
  };

  const [gameloading, setGameloading] = useState(false);
  const [transloading, setTransloading] = useState(false);
  const [withloading, setWithloading] = useState(false);
  const [depoloading, setDepoloading] = useState(false);

  const [bettels, setBettels] = useState({});
  const [transactions, setTransactions] = useState({});
  const [withdrawals, setWithdrawals] = useState({});
  const [depo, setDepo] = useState({});

  const fetchbettelList = async (page) => {
    try {
      setGameloading(true);
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const res = await axios.post(
        `${URL.API_BASE_URL + URL.getadminbattellistUser}`,
        { uid: data?.id, gamePage: page },
        config
      );

      if (res?.data) {
        setBettels(res?.data);
      }
      setGameloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTransList = async (page) => {
    try {
      setTransloading(true);
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const res = await axios.post(
        `${URL.API_BASE_URL + URL.getadmintranslistUser}`,
        { uid: data?.id, transPage: page },
        config
      );

      //  console.log(res?.data?.data);
      if (res?.data) {
        setTransactions(res?.data);
      }

      setTransloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWithdrList = async (page) => {
    try {
      setWithloading(true);
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const res = await axios.post(
        `${URL.API_BASE_URL + URL.getadminwithlistUser}`,
        { uid: data?.id, withPage: page },
        config
      );

      if (res?.data) {
        setWithdrawals(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setWithloading(false); // Ensure loading state is reset
    }
  };

  const fetchDepoList = async (page) => {
    try {
      setDepoloading(true);
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const res = await axios.post(
        `${URL.API_BASE_URL + URL.getadmindepolistUser}`,
        { uid: data?.id, depoPage: page },
        config
      );

      //  console.log(res?.data?.data);
      setDepo(res?.data);
      setDepoloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(allapiAction.getProfileDetails({ uid: data?.id }));
    return () => {};
  }, []);

  console.log(bettels);

  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <Modal show={show} onHide={handleClose}>
      <div style={{minHeight:"100px"}}>
      <img
          style={{ width: "100%" }}
          src={URL.API_BASE_URL + dataimg}
          alt=""
        />
      </div>
      
      </Modal>

      <Modal show={showcupdate} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>Approve</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-7 py-5">
            <div className="mb-10">
              <label className="form-label fw-semibold">approve:</label>

              <div>
                <select
                  className="form-select "
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-dropdown-parent="#kt_menu_637dc7729cedd"
                  data-allow-clear="true"
                  value={statusselect}
                  onChange={(e) => {
                    setstatusselect(e.target.value);
                  }}
                >
                  {/* <option></option> */}
                  <option value="pending">Pending</option>
                  <option value="reject">Rejected</option>
                  {/* <option value='complete'>In Process</option> */}
                  <option value="complete">Approve</option>
                </select>
              </div>

              {/* statusselect */}
              {/* <!--end::Input-->  */}
            </div>
          </div>
          {statusselect == "reject" && (
            <div className="px-7 py-5">
              <div className="mb-10">
                <label className="form-label fw-semibold">Reason:</label>

                <div>
                  <input
                    className="form-control bg-transparent"
                    value={statusselectreas}
                    onChange={(e) => {
                      setstatusselectreas(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdate}>
            Close
          </Button>
          <Button variant="primary" onClick={hendlesubmitup}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="tableResponsive mt-10  code-box">
        <div className="d-flex flex-column flex-column-fluid">
          {/* <!--begin::Toolbar-->  */}
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            {/* <!--begin::Toolbar container-->  */}
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              {/* <!--begin::Page title-->  */}
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                {/* <!--begin::Title-->  */}
                <h1 className="page-heading d-flex text-dark fw-bold fs-6 flex-column justify-content-center my-0">
                  User Details
                </h1>
              </div>
              {/* <!--end::Page title-->  */}
              {/* <!--begin::Actions-->  */}
            </div>
            {/* <!--end::Toolbar container-->  */}
          </div>
          {/* <!--end::Toolbar-->  */}
          {/* <!--begin::Content-->  */}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/* <!--begin::Content container-->  */}
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              {/* <!--begin::Card-->  */}
              <div className="card card-flush">
                <div className="card-body " style={{ padding: "30px" }}>
                  <div className="row">
                    <div
                      className="m-auto"
                      style={{
                        borderBottom: "2px solid gray ",
                        paddingBottom: "10px",
                        display: "flex",
                      }}
                    >
                      <div style={{ flex: "1" }}>
                        <div
                          className="col-2"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border:
                              details?.status == "Active"
                                ? "2px solid green"
                                : "2px solid black",
                          }}
                        >
                          <UserOutlined />
                        </div>
                        <div className="col-10">
                          <div>
                            <b>UID:</b> {details?.uid}
                          </div>
                          <div>
                            <b>MOBILE:</b> {details?.number}
                          </div>
                          <div>
                            <b>Email:</b> {details?.email || "Not Available"}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div style={{ color: "green" }}>
                          Account Created :{" "}
                          {new Date(details?.createdAt).toDateString()}
                        </div>
                        <div style={{ color: "skyblue" }}>
                          Last Seen:{" "}
                          {new Date(details?.updatedAt).toDateString()}
                        </div>
                      </div>
                    </div>

                    <div style={{ padding: "10px", marginBottom: "10px" }}>
                      <div className="row">
                        <div className="col-6">WALLET BALANCE</div>
                        <div className="col-6">
                          {details?.walletId?.closeBal} ₹
                        </div>
                        <div className="col-6">WON AMOUNT</div>
                        <div className="col-6">
                          {details?.walletId?.winningBal} ₹
                        </div>

                        <div className="col-6">LOSE AMOUNT</div>
                        <div className="col-6">{details?.lossAmount} ₹</div>

                        <div className="col-6">REFERAL BALANCE</div>
                        <div className="col-6">0 ₹</div>
                        <div className="col-6">REFER COUNT</div>
                        <div className="col-6">0 ₹</div>

                        <div className="col-6">TOTAL PENALTY</div>
                        <div className="col-6">{details?.totalPenalty} ₹</div>

                        <div className="col-6">TOTAL BONUS</div>
                        <div className="col-6">{details?.totalBonus} ₹</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <table className="userDetailTable">
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2">
                            KYC Doucument Details
                          </th>
                          <th className="userDetailTablet2"></th>
                        </tr>
                        <tr>
                          <td>Doucument Name</td>
                          <td>
                            {details?.accountId?.idType == "1"
                              ? "Adhaar crad"
                              : details?.accountId?.idType == "2"
                              ? "Driveing License"
                              : "Voter Id Card"}
                          </td>
                        </tr>
                        <tr>
                          <td>Doucument Number</td>
                          <td>{details?.accountId?.idNumber}</td>
                        </tr>
                        <tr>
                          <td>Doucument Image</td>
                          <td
                            className="cursor-pointer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              margin: "5px",
                              justifyContent: "center",
                              background: "blue",
                              color: "white",
                            }}
                            onClick={(e) => {
                              hendleimgView(details?.accountId?.idImage);
                            }}
                          >
                            <MobileOutlined /> SEE DOCS
                            {/* <img
                                style={{ width: "100%" }}
                                src={
                                  URL.API_BASE_URL + details?.accountId?.idImage
                                }
                                alt=""
                              /> */}
                          </td>
                        </tr>
                        <tr>
                          <td>Qr Image</td>
                          <td
                            className="cursor-pointer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              margin: "5px",
                              justifyContent: "center",
                              background: "blue",
                              color: "white",
                            }}
                            onClick={(e) => {
                              hendleimgView(details?.accountId?.qrcode);
                            }}
                          >
                            <QrcodeOutlined /> SEE QR
                          </td>
                        </tr>
                        <tr>
                          <td>UPI </td>
                          <td>{details?.accountId?.upiId}</td>
                        </tr>
                        <tr>
                          <td>Paytm Wallet </td>
                          <td>{details?.accountId?.paytmWallet}</td>
                        </tr>

                        <tr>
                          <td>Verify Doucument status</td>
                          <td>
                            {" "}
                            <button
                              data-id="2209"
                              onClick={() => {
                                hendletosubmitVerify(
                                  details?.keyverify == "Active"
                                    ? "Inactive"
                                    : "Active"
                                );
                              }}
                              className={
                                details?.keyverify == "complete"
                                  ? "btn btn-sm btn-success ms-3 mt-3"
                                  : "btn btn-sm btn-danger ms-3 mt-3"
                              }
                            >
                              {/*  */}
                              {details?.keyverify}
                              {/* <i className='fas fa-trash-alt'></i> */}
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="col-md-4">
                      <table className="userDetailTable">
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2">
                            Bank Account Details
                          </th>
                          <th className="userDetailTablet2"></th>
                        </tr>
                        <tr>
                          <td>Bank account holder Name</td>
                          <td>{details?.accountId?.userName}</td>
                        </tr>
                        <tr>
                          <td>IFSC Code</td>
                          <td>{details?.accountId?.ifseCode}</td>
                        </tr>
                        <tr>
                          <td>Bank Name</td>
                          <td>{details?.accountId?.bankName}</td>
                        </tr>
                        <tr>
                          <td>Account Number</td>
                          <td>{details?.accountId?.accountNumber}</td>
                        </tr>
                        <tr>
                          <td>Bank Passbook / cheque</td>
                          <td
                            style={{ width: "50px" }}
                            className="cursor-pointer"
                          >
                            <a
                              onClick={(e) => {
                                hendleimgView(details?.accountId?.bankpassbook);
                              }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src={
                                  URL.API_BASE_URL +
                                  details?.accountId?.bankpassbook
                                }
                                alt=""
                              />
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="col-md-4">
                      <table className="userDetailTable">
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2">Other Details</th>
                          <th className="userDetailTablet2"></th>
                        </tr>
                        <tr>
                          <td>Refer Code</td>
                          <td>{details?.referCode}</td>
                        </tr>
                        <tr>
                          <td>Refer by</td>
                          <td>{details?.referBy}</td>
                        </tr>
                        {/* <tr>
                        <td>Doucument Name</td>
                        <td>v3</td>
                      </tr> */}
                      </table>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <table className="userDetailTable">
                      <thead>
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2" colSpan={11}>
                            Game History
                          </th>
                          <th
                            className="userDetailTablet2"
                            style={{ textAlign: "end" }}
                          >
                            {bettels?.data?.length === 0 ? (
                              "-"
                            ) : !bettels?.data ? (
                              gameloading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              ) : (
                                <CaretDownOutlined
                                  onClick={async () => await fetchbettelList(1)}
                                />
                              )
                            ) : (
                              <CaretUpOutlined onClick={() => setBettels({})} />
                            )}
                          </th>
                        </tr>
                        {bettels?.data && bettels?.data?.length !== 0 && (
                          <tr>
                            <th style={{ width: "30px" }}>S.No.</th>
                            <th>Battles Id</th>
                            <th>CREATER </th>
                            <th>ACCEPTER</th>
                            <th>AMOUNT</th>
                            <th>WIN/LOSS </th>
                            <th>Prize</th>
                            <th>STATUS</th>
                            <th>GAME TYPE</th>
                            <th>WINNER</th>
                            <th>DATE</th>
                            <th>ACTION</th>
                          </tr>
                        )}
                      </thead>
                      {bettels?.data && bettels?.data?.length !== 0 && (
                        <>
                          <tbody>
                            {bettels?.data?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ width: "30px" }}>{index + 1}</td>
                                  <td>{item?.roomcode}</td>
                                  <td
                                    className="text-primary cursor-pointer"
                                    onClick={(e) => {
                                      navigate(
                                        `/user-details/${item?.owner?.uid}`
                                      );
                                    }}
                                  >
                                    {item?.owner?.uid}
                                  </td>
                                  <td
                                    className="text-primary cursor-pointer"
                                    onClick={(e) => {
                                      navigate(
                                        `/user-details/${item?.playerone?.uid}`
                                      );
                                    }}
                                  >
                                    {item?.playerone?.uid}
                                  </td>
                                  <td>{item?.amount} &#x20B9;</td>
                                  <td
                                    style={{
                                      color:
                                        item?.owner?.uid === data?.id
                                          ? item?.ownerresult === "win"
                                            ? "green"
                                            : "red"
                                          : item?.playeroneresult === "win"
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {item?.owner.uid === data?.id
                                      ? item?.ownerresult
                                      : item?.playeroneresult}
                                  </td>
                                  <td>{item?.prize} &#x20B9;</td>
                                  <td>{item?.status}</td>
                                  <td>{item?.batteltype}</td>
                                  <td
                                    className="text-primary cursor-pointer"
                                    onClick={(e) => {
                                      navigate(
                                        `/user-details/${
                                          item?.ownerresult === "win"
                                            ? item?.owner?.uid
                                            : item?.playerone?.uid
                                        }`
                                      );
                                    }}
                                  >
                                    {item?.ownerresult === "win"
                                      ? item?.owner?.uid
                                      : item?.playerone?.uid}
                                  </td>
                                  <td>
                                    {moment(item?.created_at).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )}
                                  </td>
                                  <td
                                    className="text-primary cursor-pointer "
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "center",
                                    }}
                                    onClick={(e) => {
                                      navigate(`/battle-view/${item?._id}`);
                                    }}
                                  >
                                    View
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr className="userDetailTablet">
                              <th colSpan={12}>
                                {" "}
                                <th>
                                  {" "}
                                  <CustomPagination
                                    total={bettels?.pages}
                                    current={bettels?.page}
                                    onChange={async (p) =>
                                      await fetchbettelList(p)
                                    }
                                  />
                                </th>
                              </th>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </table>
                  </div>

                  <div
                    className="row"
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <table className="userDetailTable">
                      <thead>
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2" colSpan={8}>
                            Transactions History
                          </th>
                          <th
                            className="userDetailTablet2"
                            style={{ textAlign: "end" }}
                          >
                            {transactions?.data?.length === 0 ? (
                              "-"
                            ) : !transactions?.data ? (
                              transloading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              ) : (
                                <CaretDownOutlined
                                  onClick={async () => await fetchTransList(1)}
                                />
                              )
                            ) : (
                              <CaretUpOutlined
                                onClick={() => setTransactions({})}
                              />
                            )}
                          </th>
                        </tr>
                        {transactions?.data && (
                          <tr>
                            <th style={{ width: "50px" }}>S.No.</th>
                            <th>Form</th>
                            <th>To</th>
                            <th>Previous Balance</th>
                            <th>Current Balance</th>
                            <th>Transaction ID</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Join Date</th>
                          </tr>
                        )}
                      </thead>
                      {transactions?.data && (
                        <>
                          <tbody>
                            {transactions?.data?.map((item, index) => (
                              <tr key={index}>
                                <td style={{ width: "50px" }}>{index + 1}</td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/user-details/${item.form_id?.uid}`
                                    )
                                  }
                                >
                                  {item.form_id?.uid}
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() =>
                                    navigate(`/user-details/${item.to_id?.uid}`)
                                  }
                                >
                                  {item.to_id?.uid}
                                </td>
                                <td>{item.previous_balance} &#x20B9;</td>
                                <td>{item.current_balance} &#x20B9;</td>
                                <td>{item.transaction_id ?? ""}</td>
                                <td>{item.type}</td>
                                <td>{item.description}</td>
                                <td>
                                  {moment(item.createdAt).format("DD-MM-YYYY")}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr className="userDetailTablet">
                              <th colSpan={9}>
                                <CustomPagination
                                  total={transactions?.pages}
                                  current={transactions?.page}
                                  onChange={async (p) =>
                                    await fetchTransList(p)
                                  }
                                />
                              </th>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </table>
                  </div>

                  <div
                    className="row"
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <table className="userDetailTable">
                      <thead>
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2" colSpan={6}>
                            Deposite History
                          </th>
                          <th
                            className="userDetailTablet2"
                            style={{ textAlign: "end" }}
                          >
                            {depo?.data?.length === 0 ? (
                              "-"
                            ) : !depo?.data ? (
                              depoloading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              ) : (
                                <CaretDownOutlined
                                  onClick={async () => await fetchDepoList(1)}
                                />
                              )
                            ) : (
                              <CaretUpOutlined onClick={() => setDepo({})} />
                            )}
                          </th>
                        </tr>
                        {depo?.data && depo?.data?.length !== 0 && (
                          <tr>
                            <th style={{ width: "50px" }}>S.No.</th>
                            <th>ID</th>
                            <th>UPI /BANK</th>
                            <th>AMOUNT</th>
                            <th>STATUS</th>
                            <th>DATE/TIME</th>
                            <th>GATEWAY</th>
                          </tr>
                        )}
                      </thead>
                      {depo?.data && depo?.data?.length !== 0 && (
                        <>
                          <tbody>
                            {depo?.data?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ width: "50px" }}>{index + 1}</td>
                                  <td>{item?._id}</td>
                                  <td>{item?.paymentIn}</td>
                                  <td>{item?.amount} &#x20B9;</td>
                                  <td>{item?.status}</td>
                                  <td>
                                    {moment(item?.created_at).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )}
                                  </td>
                                  <td>{item?.gateway}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr className="userDetailTablet">
                              <th colSpan={7}>
                                {" "}
                                <th>
                                  {" "}
                                  <CustomPagination
                                    total={depo?.pages}
                                    current={depo?.page}
                                    onChange={async (p) =>
                                      await fetchDepoList(p)
                                    }
                                  />
                                </th>
                              </th>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </table>
                  </div>

                  <div
                    className="row"
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <table className="userDetailTable">
                      <thead>
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2" colSpan={9}>
                            Withdrawals History
                          </th>
                          <th
                            className="userDetailTablet2"
                            style={{ textAlign: "end" }}
                          >
                            {withdrawals?.data?.length === 0 ? (
                              "-"
                            ) : !withdrawals?.data ? (
                              withloading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              ) : (
                                <CaretDownOutlined
                                  onClick={async () => await fetchWithdrList(1)}
                                />
                              )
                            ) : (
                              <CaretUpOutlined
                                onClick={() => setWithdrawals({})}
                              />
                            )}
                          </th>
                        </tr>
                        {/* S.No.														 */}
                        {withdrawals?.data &&
                          withdrawals?.data?.length !== 0 && (
                            <tr>
                              <th style={{ width: "50px" }}>S.No.</th>
                              <th>Amount</th>
                              <th>payment Mode</th>
                              <th>Account Holder</th>
                              <th>upi</th>
                              <th>Request Payment</th>
                              <th>fee</th>
                              <th>Utr Image</th>
                              <th>Request Date</th>
                              <th>Status</th>
                            </tr>
                          )}
                      </thead>
                      {withdrawals?.data && withdrawals?.data?.length !== 0 && (
                        <>
                          <tbody>
                            {withdrawals?.data?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ width: "50px" }}>{index + 1}</td>

                                  <td>{item?.current_balance} &#x20B9;</td>
                                  <td>{item?.paymentMode}</td>
                                  <td>{item?.accountHolder}</td>
                                  <td>{item?.upi}</td>
                                  <td>{item?.requestPayment} &#x20B9;</td>
                                  <td>{item?.fee}</td>
                                  <td>
                                    {" "}
                                    <div
                                      style={{ width: "50px" }}
                                      className="cursor-pointer"
                                      onClick={(e) => {
                                        hendleimgView(item?.image);
                                      }}
                                    >
                                      <img
                                        src={URL?.API_BASE_URL + item?.image}
                                        alt=""
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <span
                                      className="cursor-pointer"
                                      // onClick={() => navigate(`/users/contact/${item?.id}`)}
                                    >
                                      {moment(item?.createdAt).format(
                                        " DD/MM/YYYY "
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <button className={"success-button ml-3"}>
                                      {item?.status}
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr className="userDetailTablet">
                              <th colSpan={10}>
                                {" "}
                                <th>
                                  {" "}
                                  <CustomPagination
                                    current={withdrawals?.page}
                                    total={withdrawals?.pages}
                                    onChange={async (p) =>
                                      await fetchWithdrList(p)
                                    }
                                  />
                                </th>
                              </th>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--end::Content container-->  */}
          </div>
          {/* <!--end::Content-->  */}
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
