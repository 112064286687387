import React, { useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar';
import TopBar from './TopBar';
const { Header, Content, Footer } = Layout;

const LayoutMain = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            <Layout className='layout_css'>
                <Sidebar collapsed={collapsed} />
                <Layout className="site-layout" style={{ backgroundColor: '#4F4A45' }}>
                    <Header className="site-layout-background" style={{ padding: 0, backgroundColor: '#1E201E' }}>
                        <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '6px',
                            padding: 10,
                            minHeight: 280,
                            overflowY: 'auto',
                            backgroundColor: '#1E201E',
                            color: '#E5E5CB'
                        }}
                    >
                        <Outlet />
                    </Content>
                    {/* <Footer></Footer> */}
                </Layout>
            </Layout>
        </>
    )
}

export default LayoutMain