import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";

function RecentPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // image uploder
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const kyc_list = useSelector((state) =>
    state?.allapi?.fund_req_list ? state?.allapi?.fund_req_list : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = kyc_list?.docs ? kyc_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.addfundreqestListAdminsearch({ page: page, key: search })
      );
    } else {
      dispatch(allapiAction.addfundreqestListAdmin(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusSceernShot, setstatusSceernShot] = useState(false);
  const [statusSceernShotimg, setstatusSceernShotimg] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setstatusData(e);
  };

  console.log(statusData);
  const hendleToViewM2 = (e) => {
    setstatusSceernShot(true);
    setstatusSceernShotimg(e);
    // setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetcreate = () => {
    const data = new FormData();
    // data.append("name", statusData?.name);
    // data.append("paymenttype", statusData?.paymenttype);
    // data.append("upiid", statusData?.upiid);

    // // data.append('sellerId', selectedOption1?._id)
    // if (images?.length > 0) {
    //   data.append("image", images[0]?.file);
    // }

    dispatch(
      allapiAction.addfundreqestUpdateAdmin({
        _id: statusData?._id,
        status: statusData?.status,
      })
    );

    setstatusDataBox(false);
  };
  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deleteaccountList({
        _id: userId,
        // status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleactive = (e) => {
    dispatch(
      allapiAction.acctiveaccountlist({
        _id: e,
        // status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Screen Shot",
      dataIndex: "image",
      key: "image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              onClick={() => hendleToViewM2(URL?.API_BASE_URL + item?.image)}
            >
              <div style={{ width: "100px" }}>
                <img
                  src={URL?.API_BASE_URL + item?.image}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
              {/* {item?.amount} */}
            </span>
          </>
        );
      },
    },
    {
      title: "User Uid",
      dataIndex: "uid",
      key: "uid",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              //   onClick={() => navigate(`/user-details/${item?.userId?.uid}`)}
            >
              {item?.userId?.uid}
            </span>
          </>
        );
      },
    },
    {
      title: "User Number",
      dataIndex: "number",
      key: "number",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              //   onClick={() => navigate(`/user-details/${item?.userId?.uid}`)}
            >
              {item?.userId?.number}
            </span>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              //   onClick={() => navigate(`/user-details/${item?.userId?.uid}`)}
            >
              {item?.amount}
            </span>
          </>
        );
      },
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              //   onClick={() => navigate(`/user-details/${item?.userId?.uid}`)}
            >
              {item?.transactionId}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Payment type",
    //   dataIndex: "paymenttype",
    //   key: "paymenttype",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.paymenttype}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upi id",
    //   dataIndex: "upiid",
    //   key: "upiid",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.upiid}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Qr code ",
    //   dataIndex: "qrcodehave",
    //   key: "qrcodehave",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.qrcodehave}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Enable ",
      dataIndex: "staus",
      key: "staus",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.status}>
              <button
                className={
                  item?.status == "complete"
                    ? "success-button ml-3"
                    : "delete-button ml-3"
                }
                onClick={() => hendleToViewM(item)}
              >
                {item?.status == "complete" ? "Aproved" : item?.status}
              </button>
            </Tooltip>
          </>
        );
      },
    },

    {
      title: "Join Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact">
    //             <span
    //               className="cursor-pointer"
    //               onClick={() => navigate(`/users/contact/${item?.id}`)}
    //             >
    //               <SvgIcon.contacts className="scale-icon edit-icon" />
    //             </span>
    //           </Tooltip> */}
    //         <Tooltip title="delete">
    //           <button
    //             className="filter-button ml-3"
    //             onClick={() => showModal(item?._id)}
    //           >
    //             Delete
    //           </button>
    //         </Tooltip>
    //         {/* <Tooltip title="Add Point">
    //             <button
    //               className="filter-button ml-3  mt-5"
    //               onClick={() => hendleToViewM(item)}
    //             >
    //               Add Point
    //             </button>
    //           </Tooltip> */}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToChangeStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/point-request?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    if (search) {
      navigate("/point-request?page=" + e + "&search=" + search);
      // search
      //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
      //   return () => {};
    } else {
      //   dispatch(allapiAction.getuserList(e));

      //   return () => {};
      navigate("/point-request?page=" + e);
    }
  };

  return (
    <div>
      <Modal
        title="delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Account"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetcreate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Name</Col>
            <Col span={24}>
              {/* <Input
                name="name"
                placeholder="enter name"
                value={statusData?.name}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              /> */}
              <select
                className="form-select "
                data-kt-select2="true"
                data-placeholder="Select option"
                data-dropdown-parent="#kt_menu_637dc7729cedd"
                data-allow-clear="true"
                value={statusData?.status}
                name="status"
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                {/* <option></option> */}
                <option value="pending">Pending</option>
                <option value="complete">Approve </option>
                <option value="reject">Rejected</option>
                {/* <option value='complete'>In Process</option> */}
              </select>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* image viwe */}

      <Modal
        title="Screen Shot"
        centered
        open={statusSceernShot}
        onOk={() => setstatusSceernShot(false)}
        onCancel={() => setstatusSceernShot(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Name</Col>
            <Col span={24}>
              <div>
                <img
                  src={statusSceernShotimg}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Point Request"}
            subHeading={`Total ${kyc_list?.totalDocs || 0} Point Request`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            style={{ backgroundColor: "#1E201E", color: "white" }}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => hendleToViewM()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={""}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: kyc_list?.totalDocs ? kyc_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default RecentPayment;
