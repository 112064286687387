import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";

function Message() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const question_list = useSelector((state) =>
    state?.allapi?.get_message_list ? state?.allapi?.get_message_list : {}
  );

  console.log(question_list);

  const listdata = question_list?.docs ? question_list?.docs : [];

  console.log(listdata);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const [seleceteManger, setseleceteManger] = useState({});
  const [seleceteCateUpdate, setseleceteCateUpdate] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);

    const ansselect =
      e?.answer == e?.option1
        ? "option1"
        : e?.answer == e?.option2
        ? "option2"
        : e?.answer == e?.option3
        ? "option3"
        : e?.answer == e?.option4
        ? "option4"
        : "";

    console.log(ansselect);
    e.answer = ansselect;
    setcateDetail(e);

    if (e?.quiz !== null) {
      const seriresDetatail = e?.quiz;
      seriresDetatail.value = e?.quiz_id;
      seriresDetatail.label = e?.quiz?.name;

      setseleceteCateUpdate(seriresDetatail);
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (!seleceteManger?.id) {
      const getAns =
        cateDetail?.answer == "option1"
          ? cateDetail?.option1
          : cateDetail?.answer == "option2"
          ? cateDetail?.option2
          : cateDetail?.answer == "option3"
          ? cateDetail?.option3
          : cateDetail?.option4;

      dispatch(
        allapiAction.update_question({
          //   quiz_id: seleceteManger?.id,
          q_id: seleceteCateUpdate?.id,
          name: cateDetail?.name,
          page: page,
          quiz_id: 0,
          type: "all",
          question_id: cateDetail?.id,
          question: cateDetail?.question,
          option1: cateDetail?.option1,
          option2: cateDetail?.option2,
          option3: cateDetail?.option3,
          option4: cateDetail?.option4,
          answer: getAns,
        })
      );
    } else {
      const getAns =
        cateDetail?.answer == "option1"
          ? cateDetail?.option1
          : cateDetail?.answer == "option2"
          ? cateDetail?.option2
          : cateDetail?.answer == "option3"
          ? cateDetail?.option3
          : cateDetail?.option4;

      dispatch(
        allapiAction.update_question({
          quiz_id: seleceteManger?.id,
          q_id: seleceteCateUpdate?.id,
          name: cateDetail?.name,
          page: page,
          //   quiz_id: 0,
          type: "All",
          question_id: cateDetail?.id,
          question: cateDetail?.question,
          option1: cateDetail?.option1,
          option2: cateDetail?.option2,
          option3: cateDetail?.option3,
          option4: cateDetail?.option4,
          answer: getAns,
        })
      );
    }

    // dispatch(adminAction.getRoleList('dhsg'))
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({
    location: "1",
    priority: "red",
  });

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);

    setAddModalOpen(true);
  };

  //   console.log(cateDetailAdd);

  const [seleceteCateCreate, setseleceteCateCreate] = useState({});

  const handleOkAdd = () => {
    if (!cateDetailAdd?.message) {
      toast.error("please enter message");
    } else if (!cateDetailAdd?.location) {
      toast.error("please select location");
    } else if (!cateDetailAdd?.priority) {
      toast.error("please select color");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("question", cateDetailAdd?.message);

      data.append("option3", cateDetailAdd?.option3);
      data.append("option4", cateDetailAdd?.option4);

      dispatch(allapiAction.createMessage(cateDetailAdd));

      setcateDetailAdd({
        message: "",
        location: "",
        priority: "red",
      });
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      setseleceteCateCreate(null);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    // {
    //   title: "Color",
    //   dataIndex: "priority",
    //   key: "priority",
    // },
    {
      title: "message",
      dataIndex: "message",
      key: "message",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}

            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?._id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);

    if (!seleceteManger?.id) {
      dispatch(
        allapiAction.messagedelete({
          _id: e,
          page: page,
        })
      );
    } else {
      dispatch(
        allapiAction.messagedelete({
          _id: e,
          page: page,
        })
      );
    }

    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  useEffect(() => {
    dispatch(allapiAction.messageList({ page: 1 }));

    return () => {};
  }, []);

  const hendleTofilter = () => {
    dispatch(
      allapiAction.questionsLlist({
        page: 1,
        quiz_id: seleceteManger?.id,
        type: "All",
      })
    );

    return () => {};
  };
  const handleFilterReset = () => {
    dispatch(
      allapiAction.questionsLlist({
        page: 1,
        quiz_id: 0,
        type: "all",
      })
    );
    setseleceteManger(null);
    return () => {};
  };

  const loadOptionsgetManager = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(search);
    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL + `/api/all_quiz?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsgetQuizCreate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL + `/api/all_quiz?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsgetQuizUpdate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL + `/api/all_quiz?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: 1,
            quiz_id: seleceteManger?.id,
            type: "All",
            search: e,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: 1,
            quiz_id: 0,
            type: "all",
            search: e,
          })
        );
        // setseleceteManger(null);
        return () => {};
      }

      // dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      // return () => {};
    } else {
      // questionsLlistSearch
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlist({
            page: 1,
            quiz_id: seleceteManger?.id,
            type: "All",
            // search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlist({
            page: 1,
            quiz_id: 0,
            type: "all",
          })
        );
        // setseleceteManger(null);
        return () => {};
      }

      // dispatch(allapiAction.allcategory(e));

      // return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: e,
            quiz_id: seleceteManger?.id,
            type: "All",
            search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: e,
            quiz_id: 0,
            type: "all",
            search: search,
          })
        );

        return () => {};
      }
    } else {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlist({
            page: e,
            quiz_id: seleceteManger?.id,
            type: "All",
            // search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlist({
            page: e,
            quiz_id: 0,
            type: "all",
          })
        );
        // setseleceteManger(null);
        return () => {};
      }
    }
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Question"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24} className=" mt-15">
          <label>Select quiz</label>
          <AsyncPaginate
            // key={managerKey}
            placeholder="select quiz"
            value={seleceteCateUpdate}
            loadOptions={loadOptionsgetQuizUpdate}
            onChange={setseleceteCateUpdate}
            additional={{
              page: 1,
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Question</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="question"
            name="question"
            value={cateDetail?.question}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 1</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 1"
            name="option1"
            value={cateDetail?.option1}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 2</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 2"
            name="option2"
            value={cateDetail?.option2}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 3</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 3"
            name="option3"
            value={cateDetail?.option3}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 4</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 4"
            name="option4"
            value={cateDetail?.option4}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>answer</label>
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="answer"
            name="answer"
            value={cateDetail?.answer}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          /> */}

          <select
            name="answer"
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
            className="form-control"
            value={cateDetail?.answer}
          >
            <option value={""}>select answer</option>
            <option value={"option1"}> option 1</option>
            <option value={"option2"}> option 2</option>
            <option value={"option3"}> option 3</option>
            <option value={"option4"}> option 4</option>
          </select>
        </Col>
      </Modal>
      {/* create */}

      <Modal
        title="Create Message"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <label>color</label>

          <select
            name="priority"
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
            className="form-control"
            value={cateDetailAdd?.priority}
          >
            <option value={""}>select color</option>
            <option value={"red"}>Red</option>
            <option value={"green"}>Green</option>
          </select>
        </Col> */}
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>location</label>

          <select
            name="location"
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
            className="form-control"
            value={cateDetailAdd?.location}
          >
            <option value={""}>select location</option>
            <option value={"1"}> 1</option>
            <option value={"2"}> 2</option>
            <option value={"3"}> 3</option>
            {/* <option value={"3"}> 3</option>
            <option value={"4"}> 4</option> */}
          </select>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Message</label>
          <textarea
            autoComplete="new-off"
            className="form-control"
            placeholder="message"
            name="message"
            value={cateDetailAdd?.message}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Message"}
            subHeading={`Total ${question_list?.total || 0} Message`}
          />
        </div>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          // 
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: question_list?.total ? question_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Message;
