import { Input } from "antd";
import React, { useState } from "react";
// import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import IMAGES from "../assets/images/logo-trans.png";
import { validateLogin } from "../utils/validation";
import LeftSection from "./LeftSection";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const [show, setShow] = useState(false);
  //   const { mutate: Login } = useMutation((data) =>
  //     dispatch(UserServices.loginApi(data, navigate))
  //   );
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    const validationErrors = validateLogin(values);
    let noErrors = Object.keys(validationErrors).length == 0;
    setErrors(validationErrors);
    if (noErrors) {
      // userLogin

      //   Login(values);

      dispatch(
        allapiAction.userLogin(
          { email: values?.Email, password: values?.Password },
          navigate
        )
      );
      return () => { };
    }
  };

  const hendleToSubmit = () => {
    if (values?.Email !== "admin@gmail.com" && values?.Password !== "123456") {
      toast.error("invalid email and password");
    } else {
      toast.success("login successfully");
      localStorage.setItem("admin_token", "response?.data?.data?.auth_token");
      navigate("/");
    }
  };

  return (
    <div className="loginPage" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

      <div className="loginFormbox">
        <div className="flex justify-content-center">
          <img src={IMAGES} alt="" className="scale_img mb-30" />
        </div>

        <p style={{ fontSize: '30px', fontWeight: '900', color: 'gray' }}>Sign in to your account</p>
        <div className="loginForm">
          <div className="form-group">
            <div className="input-group">
              <input
                type="email"
                autoComplete="new-off"
                placeholder="Email"
                name="Email"
                onChange={handleChange}
                value={values?.Email || ""}
              />
            </div>
            {errors?.Email && <p className="error">{errors?.Email}</p>}
          </div>
          <div className="form-group">
            <div className="input-group">
              <input
                type={show ? "text" : "password"}
                autoComplete="new-off"
                placeholder="Password"
                name="Password"
                onChange={handleChange}
                value={values?.Password || ""}
              />
            </div>
            {errors?.Password && (
              <p className="error">{errors?.Password}</p>
            )}
          </div>

          <button
            type="button"
            className="gradient-button w-100"
            onClick={() => handleLogin()}
          >
            Sign In
          </button>
        </div>
      </div>

    </div>
  );
};

export default Login;
