import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";

function UserrTable() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getUserListseacrch({ pageNumber: page, key: search }));
    // } else {
      // dispatch(allapiAction.getuserList(1));
      dispatch(allapiAction.getUserListTop10(page));
    // }

    return () => {};
  }, [location]);
  const [page, setPage] = useState(1);

  // const page = pagev == null ? 1 : pagev;
  const hendlePahegi = (e) => {
    setPage(e);

    // console.log(location);

    // navigate("/users?page=" + e  );
    dispatch(allapiAction.getuserList(e));
    // }

    return () => {};
    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

 
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    //   filters: [
    //     {
    //       text: "Joe",
    //       value: "Joe",
    //     },
    //     {
    //       text: "Jim",
    //       value: "Jim",
    //     },
    //     {
    //       text: "Submenu",
    //       value: "Submenu",
    //       children: [
    //         {
    //           text: "Green",
    //           value: "Green",
    //         },
    //         {
    //           text: "Black",
    //           value: "Black",
    //         },
    //       ],
    //     },
    //   ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
    //   onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
    //   sortDirections: ["descend"],
    },
    {
      title: "Age",
      dataIndex: "age",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Address",
      dataIndex: "address",
    //   filters: [
    //     {
    //       text: "London",
    //       value: "London",
    //     },
    //     {
    //       text: "New York",
    //       value: "New York",
    //     },
    //   ],
    sorter: (a, b) => a.address - b.address,
    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
  ];
  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
    },
    {
      key: "4",
      name: "Jim Red",
      age: 32,
      address: "London No. 2 Lake Park",
    },
  ];

  const columnss = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
      className: "table_action_col",
      // sorter: (a, b) => a.uid - b.uid,
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              onClick={() => navigate(`/user-details/${item?.userId?.uid}`)}
            >
              {item?.userId?.uid}
            </span>
          </>
        );
      },
    },

    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              onClick={() => navigate(`/user-details/${item?.userId?.number}`)}
            >
              {item?.userId?.number}
            </span>
          </>
        );
      },
    },
    {
      title: "Wallet",
      dataIndex: "Wallet",
      key: "Wallet",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.closeBal} &#8377;
            </span>
          </>
        );
      },
    },
    {
      title: "Reffer Code",
      dataIndex: "referCode",
      key: "referCode",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.userId?.referCode}
            </span>
          </>
        );
      },
    },
    {
      title: "Join Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
  
  ];

  const listdata = users_list?.docs ? users_list?.docs : [];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  return (
    <div>
     <Table
          // className={"table antd_table background-table "}
          dataSource={listdata}
          columns={columnss}
          pagination={{
            current: parseInt(page),
            pageSize: 15,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />

        
    </div>
  );
}

export default UserrTable;
