import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteToken } from '../../utils';
import { toast } from 'react-toastify';
import axios from 'axios';
import { URL } from '../../../Redux/common/url';
import IMAGES from "../../assets/images/logo-trans.png";

const TopBar = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        DeleteToken();
        navigate("/login", { replace: true });
    }

    const [visible, setVisible] = useState(false)
    const [modal, setmodal] = useState(false)

    const [data, setData] = useState({
        email: localStorage.getItem('email'),
        old_pass: '',
        new_pass: '',
        conf_pass: ''
    })

    const handleChnagepass = async () => {
        try {
            if (data?.new_pass !== data?.conf_pass) {
                alert('password not match')
            }
            const res = await axios.post(URL.API_BASE_URL + URL.changepassword, data);

            if (res?.data?.success) {
                toast.success(res?.data?.message)
                setmodal(!modal)
            }
        } catch (error) {
            toast.error(error)
        }
    }



    return (
        <div className='flex justify-content-between align-items-center'>

            <Modal title="Change Password" open={modal} footer={null} onCancel={() => setmodal(!modal)}>
                <div className='passw-chnage'>
                    <input type='email' readonly value={data?.email} style={{ userSelect: 'none' }} />
                    <input type="text" placeholder='old password' value={data?.old_pass}
                        onChange={(e) => setData({ ...data, old_pass: e.target.value })} />

                    <input type="text" placeholder='new password' value={data?.new_pass}
                        onChange={(e) => setData({ ...data, new_pass: e.target.value })} />

                    <input type="text" placeholder='confirm password' value={data?.conf_pass}
                        onChange={(e) => setData({ ...data, conf_pass: e.target.value })} />
                    <input onClick={handleChnagepass} type="button" value="Submit " />
                </div>
            </Modal>


            <div style={{ color: 'white' }}>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })}</div>


            <div style={{display:'flex', gap:'20px'}}>
                <div>
                    <img style={{ width: '60px' }} src={IMAGES} className="cursor-pointer" alt="" onClick={() => navigate("/")} />
                </div>


                <div className='mr-20' style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',

                }}>
                    <span onClick={() => setVisible(!visible)} style={{
                        width: '30px', height: '30px', display: 'flex',
                        alignItems: 'center', justifyContent: 'center', border: '1px solid #504099',
                        borderRadius: '50%', color: '#EA1179'
                    }}>
                        <i class="fa-solid fa-user fs-6"></i>
                    </span>

                    {
                        visible &&
                        <div className='modal-profile' style={{ background: 'black' }}>
                            <div class="card-t p-3" style={{ background: 'black' }}>

                                <div class="d-flex align-items-center" style={{ background: 'black' }}>

                                    {/* <div class="image">
                                    <img src="https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80" class="rounded" width="100" />
                                </div> */}

                                    <div class="ml-3 w-100" style={{ background: 'black' }}>
                                        {/* 
                                    <h4 class="mb-0 mt-0">Alex HMorrision</h4>
                                    <span>Senior Journalist</span> */}
                                        {/* 
                                    <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">

                                        <div class="d-flex flex-column">

                                            <span class="articles">Articles</span>
                                            <span class="number1">38</span>

                                        </div>

                                        <div class="d-flex flex-column">

                                            <span class="followers">Followers</span>
                                            <span class="number2">980</span>

                                        </div>


                                        <div class="d-flex flex-column">

                                            <span class="rating">Rating</span>
                                            <span class="number3">8.9</span>

                                        </div>

                                    </div> */}


                                        <div class="button mt-2 d-flex flex-row align-items-center" style={{ background: 'black' }}>

                                            <button class="btn btn-sm btn-outline-primary w-100" onClick={() => setmodal(!modal)}>Change Password</button>
                                            <button class="btn btn-sm btn-primary w-100 ml-2" onClick={() => handleLogout()}>Logout</button>


                                        </div>


                                    </div>


                                </div>

                            </div>
                            {/* 
                        <button style={{background: 'blue', color: 'white', padding: '0px'}} >Logout <i class="fa-solid fa-right-from-bracket "></i></button> */}


                        </div>
                    }



                </div>
            </div>
        </div >
    )
}

export default TopBar