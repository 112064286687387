import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IMAGES from "../const/Image";

const Boxes2 = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );

  console.log(deshboard);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);
  return (
    <div>
      <h4 className="mx-3 font-extrabold" style={{ color: '#DFDDC7', fontWeight: '900' }}>All Todays- {new Date().toLocaleDateString()}</h4>
      <Row gutter={[0, 20]}>


        {/* today */}
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.todayUser?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Calendar} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">Today New User</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.todaysuccessGameCount?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Trophy} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                Today Success Game
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.game?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">Today Game</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.pendingKyc?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">
                Total Pending Kyc
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.todaydepositeBalance?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                Today Deposit
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.todaywithdrawalAmount?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">
                Today Withdrawal
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.todayWonBalance?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Money} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                Today Won Amount
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.todayadminWinCommission?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Money1} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">
                Today Admin Commission
              </div>
            </div>
          </div>
        </Col>

        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                0
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Money1} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>

            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">
                TODAY REFFER (3%)
              </div>
            </div>
          </div>
        </Col>

        {/* all game */}
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText color-risk">
                {deshboard?.allGame?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">All Game</div>
            </div>
          </div>
        </Col>




      </Row>
      <h4 className="mx-3 font-extrabold" style={{ color: '#DFDDC7', fontWeight: '900', marginTop: '20px' }}>All Totals</h4>

      <Row gutter={[0, 20]}>

        {/* total */}
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText">
                {deshboard?.cenelegameCount?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                Total Cancel Game
              </div>
            </div>
          </div>
        </Col>

        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText">
                {deshboard?.toatalCompleteKyc?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>

            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                Total Approved Kyc
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText">
                {deshboard?.totalwalletBalance?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Money} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>

            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                Total User Wallet Balance
              </div>
            </div>
          </div>
        </Col>

        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText ">
                {deshboard?.blockuserCount?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Users} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">
                Total Block User
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText">
                {deshboard?.adminWinCommission?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Money1} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>

            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                Total Admin Commission
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div
            className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
          >

            <div className="flex justify-content-end align-item-center">
              <div className="priceText">
                {deshboard?.user?.toLocaleString("en-IN")}
              </div>
              {/* <div style={{ width: "25px" }}>
                <img src={IMAGES.Users} style={{ width: "100%" }} alt="" />
              </div> */}
            </div>

            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">Total User</div>
            </div>
          </div>
        </Col>

      </Row>
    </div>
  );
};

export default Boxes2;
